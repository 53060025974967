'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

// The form widget depends on Modernizr. Using a custom build of only the
// minimum needed by the form widget since the standalone app doesn't
// need anything additional. Any require() calls to custom libraries like this
// need to be "required()" at the top of the file since modules that have
// dependencies to these libraries will be shimmed with the expectation of the
// library being in the global scope.
require('standalone-form-modernizr');
// Custom jqueryui build.
require('standalone-form-jquery-ui');
var formWidget = require('form-widget-2.0.0');

var $form = $('.fb-form');
var formWidgetInstance;

$form.attr('id', 'fbForm');

formWidgetInstance = formWidget.create('fbForm', {
	icons: {
		base: 'standalone-form-icon'
	}
});

formWidgetInstance.form
	.on('fbWidget-form-submit-success', function(e, data) {

		formWidgetInstance.showSubmission(data.paymentReceiptUrl, data.documentViewUrl);

	})
	.on('fbWidget-form-expired fbWidget-form-at-submission-limit', function() {

		window.location.reload();

	});
