'use strict';

var $ = (typeof window !== "undefined" ? window.jQuery : typeof global !== "undefined" ? global.jQuery : null);

var dom = require('./dom');
var settings = require('./settings');
var option = require('./option');
var outdatedMsg = require('./outdated-msg');
var selectmenu = require('./selectmenu');

var instanceMap = {};

var sqCard = {};

var create = function(formId) {

	return instanceMap[formId] = new Payment(formId);

};

var get = function(formId) {

	return instanceMap[formId];

};

var Payment = function(formId) {

	this.formId = formId;
	this.domInstance = dom.get(formId);
	this.settingsInstance = settings.get(formId);
	this.optionInstance = option.get(formId);
	this.outdatedMsgInstance = outdatedMsg.get(formId);
	this.selectmenuInstance = selectmenu.get(formId);
	this.squarePaymentForm = null;

	if (this.domInstance.form.find('.fb-payment-type').length) {

		this.$billingAddressCountry = this.domInstance.form.find('#payment-billing-address-country');
		this.$billingAddressState = this.domInstance.form.find('#payment-billing-address-state');
		this.$billingAddressCounty = this.domInstance.form.find('#payment-billing-address-county');
		this.$shippingAddressWrapper = this.domInstance.form.find('#fbPaymentShippingAddress');
		this.$shippingAddressCountry = this.$shippingAddressWrapper.find('#payment-shipping-address-country');
		this.$shippingAddressState = this.$shippingAddressWrapper.find('#payment-shipping-address-state');
		this.$shippingAddressCounty = this.$shippingAddressWrapper.find('#payment-shipping-address-county');
		this.$shippingAddressOptionsWrapper = this.$shippingAddressWrapper.find('#fbPaymentShippingAddressOptions');
		this.$shippingMethodWrapper = this.domInstance.form.find('#fbPaymentShippingMethod');
		this.$paymentDetailsWrapper = this.domInstance.form.find('#fbPaymentDetails');
		this.$paymentMethodWrapper = this.domInstance.form.find('#fbPaymentPaymentMethod');

		// Inject stripe library if necessary
		if (this.$paymentMethodWrapper.find('[data-stripe]').length && !window.Stripe) {

			this.injectStripeLibrary();

		}

		// Setup the square payment form if necessary
		const $dataSquare = this.$paymentMethodWrapper.find('[data-square]');

		if ($dataSquare.length) {

			if (!window.Square) {

				this.injectSquareLibrary($dataSquare[0].dataset.square).done(this.setupSquarePaymentForm.bind(this, $dataSquare[0].dataset.squareappid, $dataSquare[0].dataset.squarelocationid));

			} else {

				this.setupSquarePaymentForm($dataSquare[0].dataset.squareappid, $dataSquare[0].dataset.squarelocationid);

			}

		}

		this.domInstance.form
			.on('change', '#fbPaymentShippingAddressOptions input[type="radio"]', this.onShippingAddressOptionsChange.bind(this))
			.on('change', '#payment-billing-address-country', this.onBillingAddressCountryChange.bind(this))
			.on('change', '#payment-billing-address-state', this.onBillingAddressStateChange.bind(this))
			.on('change', '#payment-shipping-address-country', this.onShippingAddressCountryChange.bind(this))
			.on('change', '#payment-shipping-address-state', this.onShippingAddressStateChange.bind(this))
			.on('change', '.fb-address-county select', this.onCountyChange.bind(this))
			.on('change', '#fbPaymentShippingMethod input[type="radio"]', this.onShippingMethodChange.bind(this))
			.on('change', '#fbPaymentPaymentMethod input[type="radio"]', this.onPaymentMethodChange.bind(this))
			// .on('fbWidget-option-field-with-fees-changed', this.onOptionFieldWithFeesChange.bind(this));
			.on('fbWidget-conditional-fields-processed', this.onConditionalFieldsProcessed.bind(this));

	}

	this.domInstance.form.on('fbWidget-destroy', this.destroy.bind(this));

};

Payment.prototype.destroy = function() {

	delete instanceMap[this.formId];

};

Payment.prototype.onShippingAddressOptionsChange = function(e) {

	var $radio = $(e.target).closest('#fbPaymentShippingAddressOptions input[type="radio"]');
	var selectedOption = this.getSelectedOptionId($radio[0].id);

	if (selectedOption === 0) {

		this.$shippingAddressOptionsWrapper.addClass('fb-payment-shipping-address-options-same');

		if (this.doesCountyOptionExist(this.$billingAddressCounty)) {

			if (this.isCountyPopulated(this.$billingAddressCounty)) {

				this.showCounty(this.$billingAddressCounty);

			}

		}

	} else {

		this.$shippingAddressOptionsWrapper.removeClass('fb-payment-shipping-address-options-same');

		// County only matters for tax purposes and shipping address will
		// now be used for taxes, so we don't need the billing address
		// county any more, if it even existed.
		if (this.doesCountyOptionExist(this.$billingAddressCounty)) {

			this.hideCounty(this.$billingAddressCounty);

		}

	}

	// We could have different data in the billing and shipping address
	// fields. So each time we toggle, we need to refresh.
	this.getShippingMethods();
	this.getPaymentDetails();

	this.domInstance.triggerFormEvent('fbWidget-payment-shipping-address-option-changed');

};

Payment.prototype.onBillingAddressCountryChange = function() {

	console.log('Payment billing address country changed.');

	if (this.isShippingAndBillingAddressSame()) {

		if (this.hasShipping()) {

			// Country change may change cost of shipping methods.
			this.getShippingMethods();

		}

		if (this.doesCountyOptionExist(this.$billingAddressCounty)) {

			// We no longer have a selected state, so clear and hide counties.
			this.removeCounties(this.$billingAddressCounty);

		}

		// Country change may change cost of shipping and tax.
		this.getPaymentDetails({isCountryChange: true});

	}

};

Payment.prototype.onBillingAddressStateChange = function() {

	console.log('Payment billing address state changed.');

	if (this.isShippingAndBillingAddressSame()) {

		if (this.doesCountyOptionExist(this.$billingAddressCounty)) {

			this.getCounties(this.$billingAddressCounty);

		}

		// State change may change cost of tax.
		this.getPaymentDetails({isStateChange: true});

	}

};

Payment.prototype.onShippingAddressCountryChange = function() {

	console.log('Payment shipping address country changed.');

	// Country change may change cost of shipping methods.
	this.getShippingMethods();

	if (this.doesCountyOptionExist(this.$shippingAddressCounty)) {

		// We no longer have a selected state, so clear and hide counties.
		this.removeCounties(this.$shippingAddressCounty);

	}

	// Country change may change cost of shipping and tax.
	this.getPaymentDetails({isCountryChange: true});

};

Payment.prototype.onShippingAddressStateChange = function() {

	console.log('Payment shipping address state changed.');

	if (this.doesCountyOptionExist(this.$shippingAddressCounty)) {

		this.getCounties(this.$shippingAddressCounty);

	}

	// State change may change cost of tax.
	this.getPaymentDetails({isStateChange: true});

};

Payment.prototype.onCountyChange = function() {

	console.log('Payment address county changed.');

	// County change may change cost of tax.
	this.getPaymentDetails();

};

Payment.prototype.onShippingMethodChange = function() {

	console.log('Payment shipping method changed.');

	this.getPaymentDetails();

};

Payment.prototype.onPaymentMethodChange = function() {

	this.domInstance.triggerFormEvent('fbWidget-payment-method-changed');

};

Payment.prototype.onConditionalFieldsProcessed = function() {

	// Now that all conditionally linked fields have been processed (shown and hidden), recalculate the payment details in case we just hid some fields that options with extra fees

	if (this.hasShipping()) {

		// This form is collecting shipping information and the sub total
		// just changed, which may change shipping costs that are based
		// on percentages.
		this.getShippingMethods();

	}

	this.getPaymentDetails();

};

Payment.prototype.hasShipping = function() {

	return !!this.$shippingAddressWrapper.length;

};

Payment.prototype.isShippingAndBillingAddressSame = function() {

	return !this.hasShipping() || this.hasShipping() && this.$shippingAddressOptionsWrapper.hasClass('fb-payment-shipping-address-options-same');

};

Payment.prototype.getSelectedOptionId = function(id) {

	return parseInt(id.split('-').pop(), 10);

};

Payment.prototype.getSelectedShippingMethod = function() {

	return this.$shippingMethodWrapper.find(':checked');

};

Payment.prototype.getShippingId = function() {

	return this.getSelectedShippingMethod().attr('data-shipping-id');

};

Payment.prototype.getSelectedLocation = function() {

	var data = {};

	if (this.isShippingAndBillingAddressSame()) {

		data.countryCode = this.$billingAddressCountry.val();
		data.stateCode = this.$billingAddressState.val();
		data.county = this.doesCountyOptionExist(this.$billingAddressCounty) ? this.$billingAddressCounty.val() : '';

	} else {

		data.countryCode = this.$shippingAddressCountry.val();
		data.stateCode = this.$shippingAddressState.val();
		data.county = this.doesCountyOptionExist(this.$shippingAddressCounty) ? this.$shippingAddressCounty.val() : '';

	}

	return data;

};

Payment.prototype.getShippingMethods = function() {

	var locationData = this.getSelectedLocation();
	var countryCode = locationData.countryCode;

	return $.ajax({
		method: 'POST',
		cache: false,
		dataType: 'json',
		url: this.settingsInstance.shippingMethodsUrl,
		context: this,
		data: {
			data: JSON.stringify({
				dataVersionKey: this.settingsInstance.formDataVersionKey,
				selectedOption: this.getSelectedOptionId(this.getSelectedShippingMethod()[0].id) + 1,
				countryCode: countryCode,
				extraFees: this.optionInstance.getExtraFees()
			})
		}
	}).done(function(response) {

		if (response.status) {

			this.$shippingMethodWrapper.html(response.html);

		} else {

			this.outdatedMsgInstance.show(response.msg);

		}

	});

};

Payment.prototype.getPaymentDetails = function(opts) {

	var locationData = this.getSelectedLocation();
	var data = {
		dataVersionKey: this.settingsInstance.formDataVersionKey,
		extraFees: this.optionInstance.getExtraFees(),
		countryCode: locationData.countryCode,
		stateCode: locationData.stateCode,
		county: locationData.county
	};
	var options = opts || {};

	if (options.isCountryChange) {

		// Allow the option to force an empty state/county for when the country changes,
		// but the state/county drop down still has a value from the old country.
		data.stateCode = '';
		data.county = '';

	} else if (options.isStateChange) {

		data.county = '';

	}

	if (this.hasShipping()) {

		data.shippingId = this.getShippingId();

	}

	return $.ajax({
		method: 'POST',
		cache: false,
		dataType: 'json',
		url: this.settingsInstance.paymentDetailsUrl,
		context: this,
		data: {
			data: JSON.stringify(data)
		}
	}).done(function(response) {

		if (response.status) {

			this.$paymentDetailsWrapper.html(response.html);

		} else {

			this.outdatedMsgInstance.show(response.msg);

		}

	});

};

Payment.prototype.getCounties = function($target) {

	var locationData = this.getSelectedLocation();
	var data = {
		dataVersionKey: this.settingsInstance.formDataVersionKey,
		countryCode: locationData.countryCode,
		stateCode: locationData.stateCode
	};

	return $.ajax({
		type: 'post',
		cache: false,
		dataType: 'json',
		url: this.settingsInstance.paymentCountiesUrl,
		context: this,
		data: {
			data: JSON.stringify(data)
		}
	}).done(function(response) {

		var $html = $(response.html);

		if (response.status) {

			// Retain the first option, as it could have title text and not the standard "Select"
			$target.html($target.children().first().add($html));
			this.selectmenuInstance.sync($target);
			this.toggleCounty($target, this.isCountyPopulated($target));

		} else {

			this.outdatedMsgInstance.show(response.msg);

		}

	});

};

Payment.prototype.doesCountyOptionExist = function($target) {

	return $target.length;

};

Payment.prototype.isCountyPopulated = function($target) {

	// The first/default option will always be there
	return $target[0].length > 1;

};

Payment.prototype.getCountyWrapper = function($target) {

	return $target.closest('.fb-address-county');

};

Payment.prototype.toggleCounty = function($target, isVisible) {

	this.getCountyWrapper($target).toggleClass('fb-address-county-is-visible', isVisible);

};

Payment.prototype.hideCounty = function($target) {

	this.toggleCounty($target, false);

};

Payment.prototype.showCounty = function($target) {

	this.toggleCounty($target, true);

};

Payment.prototype.removeCounties = function($target) {

	this.hideCounty($target);
	// Keep the first option as it may be the title of the field
	$target.html($target.children().first());
	this.selectmenuInstance.sync($target);

};

Payment.prototype.injectStripeLibrary = function() {

	console.log('Injecting Stripe library!');

	$.ajax({
		url: 'https://js.stripe.com/v2/',
		dataType: 'script',
		cache: true
	});

};

Payment.prototype.injectSquareLibrary = function(environment) {

	console.log('Injecting Square library!');

	var jsPath;

	if(environment === 'development'){
		jsPath = 'https://sandbox.web.squarecdn.com/v1/square.js';
	} else {
		jsPath = 'https://web.squarecdn.com/v1/square.js';
	}

	return $.ajax({
		url: jsPath,
		dataType: 'script',
		cache: true
	});

};

Payment.prototype.setupSquarePaymentForm = function(appId, locationId) {

	var sqPayments = window.Square.payments(appId, locationId);

	const cardPromise = sqPayments.card();
	cardPromise.then(function(card){
		sqCard = card;
		sqCard.attach('#sq-ccbox');
	});

};

Payment.prototype.getSelectedMerchant = function() {

	return this.$paymentMethodWrapper.find('input[type="radio"]:checked');

};

Payment.prototype.isMerchantStripe = function() {

	return this.getSelectedMerchant()[0].hasAttribute('data-stripe');

};

Payment.prototype.isMerchantSquare = function() {

	return this.getSelectedMerchant()[0].hasAttribute('data-square');

};

Payment.prototype.getEmptyCCData = function() {

	return {
		type: '',
		name: '',
		number: '',
		expMonth: '',
		expYear: '',
		verification: ''
	};

};

Payment.prototype.isPaymentType = function($col) {

	return $col.hasClass('fb-payment-type');

};

Payment.prototype.processCredit = function(paymentData) {

	return $.Deferred(function(dfd) {

		if (paymentData) {

			if (this.isMerchantStripe()) {

				// Dealing with Stripe, so we need to send them the credit
				// card info to get a token, which we'll send to the server
				// in place of the credit card info

				window.Stripe.setPublishableKey(this.domInstance.form.attr('data-spk'));

				window.Stripe.createToken({
					'name': paymentData.value.cc.name,
					'number': paymentData.value.cc.number,
					'cvc': paymentData.value.cc.verification,
					'exp_month': paymentData.value.cc.expMonth,
					'exp_year': paymentData.value.cc.expYear,
					'address_line1': paymentData.value.billingAddress[0].value,
					'address_line2': paymentData.value.billingAddress[1].value,
					'address_state': paymentData.value.billingAddress[4].value,
					// Always look for zip as last element since the presence
					// of a county makes its position variable
					'address_zip': paymentData.value.billingAddress[paymentData.value.billingAddress.length - 1].value,
					'address_country': paymentData.value.billingAddress[3].value
				}, function(status, response) {

					// Clear out cc data, we don't want/need to send it to server
					paymentData.value.cc = this.getEmptyCCData();

					if (response.error) {

						paymentData.value.cc.token = '';
						paymentData.value.cc.error = response.error;

					} else {

						paymentData.value.cc.token = response.id;
						paymentData.value.cc.error = {};

					}

					dfd.resolve();

				}.bind(this));

			} else if (this.isMerchantSquare()) {

				this.paymentData = paymentData;
				this.dfd = dfd;

				const tokenPromise = sqCard.tokenize();

				tokenPromise.then(function(tokenResult){

					if (tokenResult.status === 'OK') {

						const token = tokenResult.token;
						paymentData.value.cc.token = token;
						paymentData.value.cc.error = {};

					} else {

						paymentData.value.cc.token = '';
						// https://developer.squareup.com/reference/square/payments-api/create-payment#response__property-errors
						paymentData.value.cc.error = tokenResult.errors[0];

					}

					dfd.resolve();
					
				});

			} else {

				// This is a "regular" credit card merchant, we'll send the
				// credit card info to the server for processing

				dfd.resolve();

			}

		} else {

			// This form does not have a payment field or it was hidden at the time of submission, so there is no processing to do

			dfd.resolve();

		}

	}.bind(this)).promise();

};

Payment.prototype.processPaypal = function(paypalData) {

	// Post to paypal using html form because an ajax post will be blocked
	// due to cross origin restraints
	var form = '<form method="POST" action="' + paypalData.paymentUrl + '">';

	console.log('paypalData', paypalData);

	Object.keys(paypalData.data).forEach(function(key) {

		form += '<input type="hidden" name="' + key + '" value="' + paypalData.data[key] + '">';

	});

	form += '</form';

	$(form).appendTo(this.domInstance.body).submit();

};

Payment.prototype.getVal = function($col) {

	var name = 'Payment';
	var $billingAddressWrapper = $col.find('.fb-payment-billing-address');
	var $billingAddressInputs = this.domInstance.getFieldInputs($billingAddressWrapper);
	var $shippingAddressInputs;
	var $selectedMerchant = this.getSelectedMerchant();
	var $merchantForm;
	var vals = {
		billingAddress: []
	};

	// Get billing address data.
	$billingAddressInputs.each(function(i) {

		var $el = $billingAddressInputs.eq(i);

		vals.billingAddress.push({
			name: this.domInstance.getNestedFieldTitleText($el),
			value: $el.val()
		});

	}.bind(this));

	if (this.hasShipping()) {

		// This form is collecting shipping address data.

		vals.shippingAddress = [];

		if (this.isShippingAndBillingAddressSame()) {

			// Billing and shipping address are the same, just copy.
			$.extend(vals.shippingAddress, vals.billingAddress);

		} else {

			// Get shipping address data.
			$shippingAddressInputs = this.domInstance.getFieldInputs(this.$shippingAddressWrapper.find('.fb-address'));

			$shippingAddressInputs.each(function(i) {

				var $el = $shippingAddressInputs.eq(i);

				vals.shippingAddress.push({
					name: this.domInstance.getNestedFieldTitleText($el),
					value: $el.val()
				});

			}.bind(this));

		}

		// Get the shipping method id.
		vals.shippingId = this.getShippingId();

	}

	// Get the merchant id.
	vals.merchantId = $selectedMerchant.attr('data-merchant-id');

	// Get the credit card details.
	$merchantForm = this.$paymentMethodWrapper.find('#fbPaymentMerchantForm' + vals.merchantId);

	if ($merchantForm.length) {

		// This merchant has a form for credit card data
		vals.cc = this.getEmptyCCData();

		if (!this.isMerchantSquare()) {

			vals.cc.type = $merchantForm.find('select[id$="cc' + vals.merchantId + '"]').val();
			vals.cc.name = $merchantForm.find('input[id$="name' + vals.merchantId + '"]').val();
			vals.cc.number = $merchantForm.find('input[id$="number' + vals.merchantId + '"]').val();
			vals.cc.expMonth = $merchantForm.find('select[id$="month' + vals.merchantId + '"]').val();
			vals.cc.expYear = $merchantForm.find('select[id$="year' + vals.merchantId + '"]').val();
			vals.cc.verification = $merchantForm.find('input[id$="verification' + vals.merchantId + '"]').val();

		}

	}

	return {
		name: name,
		value: vals
	};

};

module.exports = {
	create: create,
	get: get
};
